import {Component, EventEmitter, Input, Output} from '@angular/core';
import Article from 'src/smoothr-web-app-core/models/Article';
import {numberD} from 'src/smoothr-web-app-core/utils/utils';
@Component({
	selector: 'app-accordion-list',
	templateUrl: './accordion-list.component.html',
	styleUrls: ['./accordion-list.component.scss']
})
export class AccordionListComponent {
	@Input()
	article: Article;
	@Output()
	change: EventEmitter<string> = new EventEmitter<string>();
	numberD = numberD;
	public isMenuOpen: boolean = false;

	constructor() {}

	ngOnInit() {}
	public toggleAccordion(): void {
		this.isMenuOpen = !this.isMenuOpen;
	}
	public broadcastName(name: string): void {
		this.change.emit(name);
	}
	isAllergens() {
		if (
			this.article &&
			this.article.compound &&
			this.article.compound.allergens
		) {
			return Object.values(this.article?.compound?.allergens).find(
				it => it === true
			);
		}
	}
	isAddit() {
		if (
			this.article &&
			this.article.compound &&
			this.article.compound.additives
		) {
			return Object.values(this.article?.compound?.additives).find(
				it => it === true
			);
		}
	}
	isHaveAnyValueMoreZero(article: Article) {
		if (article.nutritions) {
			return Object.values(article.nutritions)
				.map(it => (numberD(it) ? numberD(it) : 0))
				.find(it => it > 0);
		}
	}
	// isHaveTrueValue(article: Article) {
	// 	return Object.values(article?.nutritions).find(it => it === true);
	// }
	// isHaveAnyValueMoreZero(article: Article) {
	// 	return Object.values(article.nutritions)
	// 		.map(it => (numberD(it) ? numberD(it) : 0))
	// 		.find(it => it > 0);
	// }
}
