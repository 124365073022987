import {HomePage} from 'src/app/pages/home/home.page';
import {environment} from 'src/environments/environment';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-toolbar-logo',
	templateUrl: './toolbar-logo.component.html',
	styleUrls: ['./toolbar-logo.component.scss']
})
export class ToolbarLogoComponent {
	@Input() showCross = false;
	@Input() order;
	@Output() close = new EventEmitter<void>();
	@Output() openchangeTable = new EventEmitter<void>();
	languageEnum = Language;
	environment = environment;
	pt = PreorderType;

	constructor(
		private router: Router,
		public repository: RepositoryService,
		private modalCtrl: ModalController,
		public translate: TranslateService
	) {}
	home() {
		HomePage.navigate(this.router, this.repository);
	}
	closeWindow() {
		this.modalCtrl.dismiss();
	}
	changeTable() {
		this.openchangeTable.emit();
	}
	getCurrentLanguage() {
		return this.translate.currentLang;
	}
	changeLang(value: string) {
		this.translate.setDefaultLang(value);
		this.translate.use(value);
	}
}
