import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const sashimiCustomerGroup = 'sashimi';
export const customerGroup = sashimiCustomerGroup;

const supportEmail = {
	sashimi: 'sashimi@smoothr.de'
};
const firebaseConfig = {
	sashimi: {
		apiKey: 'AIzaSyAqPFTUqfyWP-vMBvPCQaX8PGs2EEVgyIE',
		authDomain: 'sashimi-d2b6b.firebaseapp.com',
		projectId: 'sashimi-d2b6b',
		storageBucket: 'sashimi-d2b6b.appspot.com',
		messagingSenderId: '367625473148',
		appId: '1:367625473148:web:72f18bb22fcad888dc0df9',
		measurementId: 'G-N7DZCVPH0E'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAYQ-7r_ki5pr20A8CN-A2dzwdOFqH1ZYw',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment
};
