import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TypeOfDeinFlow} from 'src/maps/enum/typeOfDeinin';

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss']
})
export class MapPage {
	static url = 'map';
	public typeOfDeinFlow = TypeOfDeinFlow.SELECT_TABLE;
	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}
}
