import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const sashimiCustomerGroup = 'sashimi';
export const customerGroup = sashimiCustomerGroup;

const supportEmail = {
	sashimi: 'sashimi@smoothr.de'
};
const firebaseConfig = {
	sashimi: {
		apiKey: 'AIzaSyAqPFTUqfyWP-vMBvPCQaX8PGs2EEVgyIE',
		authDomain: 'sashimi-d2b6b.firebaseapp.com',
		projectId: 'sashimi-d2b6b',
		storageBucket: 'sashimi-d2b6b.appspot.com',
		messagingSenderId: '367625473148',
		appId: '1:367625473148:web:de7c88d38edecd2edc0df9',
		measurementId: 'G-NQ5EW5B14G'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyCVP1-jJmE_3I0lHC_RCSJWFB9-2qkM0_U',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment
};
